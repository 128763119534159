/**
 * Author: leo Date: 30/01/2018
 */

import {link} from "autolinker";
import {TIME} from "../constants/time";

/**
 * A collection of various general-purpose helper methods.
 */
export class Utils {
	/**
	 * Checks if provided object is a Moment.js instance.
	 * @param obj An object to test
	 * @returns {boolean} true if the object is a Moment.js instance, false otherwise.
	 */
	public static isMomentInstance(obj: any): boolean {
		return obj && (obj as any)._isAMomentObject;
	}

	/**
	 * generate random id
	 * @see https://stackoverflow.com/a/105074/7119219
	 */
	public static guid(): string {
		const s4 = () => {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		};
		return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
	}

	/** Capitalises the first character of the given string */
	public static capitalize(str: string): string {
		return str.substr(0, 1).toUpperCase() + str.substr(1);
	}

	/**
	 * Shuffles a given array.
	 * https://bost.ocks.org/mike/shuffle/
	 * @param {any[]} array
	 * @returns {any[]}
	 */
	public static shuffle<T>(array: T[]): T[] {
		let counter = array.length;

		// While there are elements in the array
		while (counter > 0) {
			// Pick a random index
			let index = Math.floor(Math.random() * counter);

			// Decrease counter by 1
			counter--;

			// And swap the last element with it
			let temp = array[counter];
			array[counter] = array[index];
			array[index] = temp;
		}

		return array;
	}

	//FIXME: Ruben added a method with a similar functionality `capitalize()`, these two need to be merged.
	//FIXME: Btw, `capitalize()` does not seem to be a good name, since it sounds as if it capitalises the whole string.
	/**
	 * Capitalises the first character of a string (if possible)
	 * @param str {string}
	 * @returns {string}
	 */
	public static capitalizeFirstChar(str: string): string {
		return (str || "").charAt(0).toUpperCase() + (str || "").slice(1).toLowerCase();
	}

	/**
	 * Lowercases the first character of a string (if possible)
	 * @param str {string}
	 * @returns {string}
	 */
	public static lowercaseFirstChar(str: string): string {
		return str.charAt(0).toLowerCase() + str.slice(1);
	}

	/**
	 * Removes all the space characters from a string and returns the result
	 * @param {string} str
	 * @returns {string}
	 */
	public static removeSpaces(str: string): string {
		return str.trim().split(" ").join("");
	}

	/**
	 * Returns true if string is null, undefined, or has `0` length after the string is trimmed.
	 *
	 * @param {string} str
	 */
	public static stringHasNoValue(str: string): boolean {
		return !str || !str.trim();
	}

	/**
	 * Remove object property by `propertyKey`.
	 *
	 * Returns object without property keyed by `propertyKey`
	 *
	 * @param {T extends Object} obj
	 * @param {string} propertyKey
	 */
	public static removePropertyFromObject<T extends Object>(obj: T, propertyKey: string): {} {
		return Object.keys(obj)
			.filter((k) => k !== propertyKey)
			.reduce(
				(result, k) => ({
					...result,
					[k]: obj[k],
				}),
				{},
			);
	}

	/**
	 * This method is using `autolinker` library
	 * The purpose of this method is to search through text string for url, email, etc.
	 * and convert them to `<a href={link}>{link}</a>`
	 *
	 * Doc: "Utility to Automatically Link URLs, Email Addresses, Phone Numbers, Twitter handles, and Hashtags in a given block of text/HTML"
	 * @see https://github.com/gregjacobs/Autolinker.js/
	 *
	 * @param {string} text
	 */
	public static convertToLink(text: string): string {
		return link(text, {newWindow: true});
	}

	/**
	 * A convenience function for measuring time between events, it can be used in benchmarking.
	 * The function saves the timestamp of when it was invoked.
	 * It also returns a trigger object with a single method `getDuration()`.
	 * Each time `getDuration()` is called, it returns the difference in seconds between the current timestamp and the initial timestamp.
	 */
	public static createTimer(): {getDuration: () => number} {
		const start: number = Date.now();

		return {
			getDuration: () => {
				const end: number = Date.now();

				return Math.abs(start - end) / TIME.MILLISECONDS_IN_SECOND;
			},
		};
	}

	/**
	 * To check if any given data has no value but not ZERO
	 * if the given argument instance of any class name or non anonymous object, return false
	 * @param data
	 * @returns Boolean
	 */
	public static isEmpty(data: any[] | string | number | Object): boolean {
		if (data instanceof Object && !["Array", "Object"].includes(data.constructor.name)) {
			return false;
		}
		const isEmptyArray = Array.isArray(data) && data.length === 0;
		const isEmptyObject = data instanceof Object && Object.values(data)?.length === 0;
		return data === null || data === "" || data === undefined || isEmptyArray || isEmptyObject;
	}
}
