import * as Sentry from "@sentry/react";
import {Integrations as TracingIntegrations} from "@sentry/tracing";
import {history} from "../helpers/routerHistory";
import {AppConfig} from "../ts/app/AppConfig";
import {shouldFilterError} from "./sentryErrorFilters";

export function initSentry() {
	if (AppConfig.isDev) {
		return;
	}
	let options: Sentry.BrowserOptions = {};

	options.dsn = AppConfig.sentryDSN;
	options.environment = AppConfig.env;
	options.release = "portal_" + AppConfig.version;
	options.attachStacktrace = true;
	options.beforeBreadcrumb = filterBreadcrumb;
	options.beforeSend = filterBeforeSend;
	options.maxBreadcrumbs = 100;
	options.integrations = [
		new Sentry.Integrations.Breadcrumbs({console: false}),
		new TracingIntegrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
		}),
	];
	// https://docs.sentry.io/platforms/javascript/guides/react/#monitor-performance
	// https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#tracing-options
	options.tracesSampleRate = 1.0;

	Sentry.init(options);
}

/**
 * List of breadcrumb xhr and fetch urls to be filtered so that it won't be shown in sentry breadcrumbs
 */
const filteredBreadcrumbURLs = ["segment.io", AppConfig.internetConnectionLongPollingUrl];
/**
 * Filter out breadcrumb so it won't be shown in sentry
 */
function filterBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
	const {category, data} = breadcrumb;
	if (category === "xhr" || category === "fetch") {
		const shouldFilter = filteredBreadcrumbURLs.some((url) => data.url.includes(url));
		if (shouldFilter) {
			return null;
		}
	}
	return breadcrumb;
}

/**
 * Filter out some event that don't need to be reported to sentry
 *
 * @param event
 * @param hint
 */
function filterBeforeSend(event: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event {
	const shouldReportSentryError = () => {
		const err = hint?.originalException;
		if (!err || typeof err === "string") {
			return true;
		}
		return !shouldFilterError(err);
	};
	if (!shouldReportSentryError()) {
		return null;
	}
	return event;
}

export function identifySentryUser(externalId: string) {
	Sentry.setUser({id: externalId || "unknown"});
}

export function unidentifySentryUser() {
	Sentry.setUser(null);
}
