import {Injectable} from "../../../IoC/Injectable";
import autobind from "autobind-decorator";
import {IStorage} from "./IStorage";
import {StorageKeys} from "./StorageKeys";
import {StorageBools} from "./StorageBools";

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// NB:   DO NOT IMPORT `IoC/DISC` into this file! (it breaks `VideoCallApp`)
//
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

/**
 * This service provides access to the local storage.
 * At this point it relies on cookies only.
 * It will be extended to use browser's web storage if larger amounts of data will have to be persisted on the client.
 */

export enum StorageType {
	WEB_STORAGE = "webStorage",
}

@autobind
class Storage implements IStorage, Injectable {
	public readonly c: string = "[Storage] ";

	/**
	 * Storage type to determine which storage to use to store data.
	 *
	 * @type {StorageType.WEB_STORAGE}
	 */
	private storageType: StorageType = StorageType.WEB_STORAGE;

	/**
	 * @inheritDoc
	 */
	public init(storageType?: StorageType): void {
		if (storageType) {
			this.storageType = storageType;
		}
	}

	/**
	 * @inheritDoc
	 */
	public read(key: StorageKeys): string | StorageBools | object {
		return localStorage.getItem(key);
	}

	/**
	 * Similar with read, but with type hint
	 */
	public readFromLocalStorage<T>(key: StorageKeys): T | null {
		try {
			return JSON.parse(localStorage.getItem(key)) as T;
		} catch (e) {
			return null;
		}
	}

	/**
	 * @inheritDoc
	 */
	public write(key: StorageKeys, value: string | StorageBools | object): void {
		if (typeof value !== "string") {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.setItem(key, value);
		}
	}

	/**
	 * @inheritDoc
	 */
	public remove(key: StorageKeys): void {
		localStorage.removeItem(key);
	}

	/**
	 * @inheritDoc
	 */
	public contains(key: StorageKeys): boolean {
		// let result:any = Cookies.get(key);
		// return !!result;
		return !!this.read(key);
	}

	// An inline unit test
	// public selfTest():void {
	//
	//     this.log.debug("Hey-hey! A quick storage self-test...");
	//
	//     const SESS_ID: string = "1234567";
	//
	//     this.log.debug( this.c + "a non-existent cookie. \t\tPass: " + (this.contains(StorageKeys.SESSION_ID) === false));
	//
	//     this.log.debug( this.c + "writing a session ID ...");
	//     this.write(StorageKeys.SESSION_ID, SESS_ID);
	//
	//     this.log.debug( this.c + "read the session ID. \t\tPass: " + (this.read(StorageKeys.SESSION_ID) === SESS_ID )); // console.log( "this.read(StorageKeys.SESSION_ID = " + (typeof this.read(StorageKeys.SESSION_ID)));  console.log( "SESS_ID  = " +(typeof SESS_ID));
	//
	//     this.log.debug( this.c + "removing the session ID ...");
	//     this.remove(StorageKeys.SESSION_ID);
	//     this.log.debug( this.c + "Check if the removed. \t\tPass: " + (this.contains(StorageKeys.SESSION_ID) === false));
	//
	//     this.log.debug( this.c + "Reading the removed. Expecting null. \t\tPass: " + (this.read(StorageKeys.SESSION_ID) === null ));
	//
	//
	//     const SESS_ID_STR: string = "habba-habba";
	//     const SESS_OBJECT: object = {session:SESS_ID_STR};
	//
	//     this.log.debug( this.c + "writing a session ID *object*...");
	//     this.write(StorageKeys.SESSION_ID, SESS_OBJECT);
	//
	//     let sessObj:any = this.read(StorageKeys.SESSION_ID);
	//     this.log.debug( this.c + "read the session object. \t\tPass: " + (sessObj.session === SESS_ID_STR));
	//
	// }
}

const storage = new Storage();
export default storage;
