import {
	NetworkRequestFailedError,
	UnauthorizedError,
	ChatTimeoutError,
	ChatNoSocketConnectionError,
	NotFoundError,
	ForbiddenError,
	BadRequestError,
} from "@sense-os/goalie-js";
import {TwilioError} from "twilio-video";

/**
 * Returns true if goalie-js SDK throws NetworkRequestFailedError
 * @param err
 */
export const isNetworkFailureError = (err: Error) => err instanceof NetworkRequestFailedError;
/**
 * Returns true if goalie-js SDK throws UnauthorizedError, or SwaggerSDK (adapted by goalie-js) throws required paramater error
 *
 * @param err
 */
export const isUnauthorizedError = (err: Error) => {
	// Empty token passed to swagger SDK
	const swaggerUnauthorizedError = err.name.includes("authorization") || err.message.includes("authorization");
	// Sense SDK Unauthorized error (Token is expired)
	const isUnauthorized = err instanceof UnauthorizedError;
	return swaggerUnauthorizedError || isUnauthorized;
};

export const isNotFoundError = (err: Error) => err instanceof NotFoundError;

export const isForbiddenError = (err: Error) => err instanceof ForbiddenError;

export const isBadRequestError = (err: Error) => err instanceof BadRequestError;

export const isChatTimeoutError = (err: Error) => err instanceof ChatTimeoutError || err.name.includes("TimeoutError");

export const isChatSocketError = (err: Error) =>
	err instanceof ChatNoSocketConnectionError || err.message.includes("this.socket");

export const isLoadingChunkError = (err: Error) => {
	return err?.name.toLowerCase().includes("chunkloaderror");
};

const isTwilioError = (err: Error): err is TwilioError => {
	return err?.name.toLowerCase().includes("twilioerror");
};

// https://www.twilio.com/docs/api/errors
export const isTwilioMediaError = (err: Error) => {
	if (!isTwilioError(err)) {
		return;
	}
	return err.code === 53407 || err.code === 53405;
};
export const isTwilioSignallingError = (err: TwilioError) => {
	if (!isTwilioError(err)) {
		return;
	}
	return err.code >= 53000 && err.code <= 53004;
};
