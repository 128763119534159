import {
	isUnauthorizedError,
	isNetworkFailureError,
	isChatSocketError,
	isChatTimeoutError,
	isForbiddenError,
	isNotFoundError,
	isTwilioMediaError,
	isLoadingChunkError,
	isBadRequestError,
} from "./errorHandlerUtils";

type ErrorFilterFn = (err: Error) => boolean;

const errorFilterFns: ErrorFilterFn[] = [
	isUnauthorizedError,
	isNetworkFailureError,
	isChatSocketError,
	isChatTimeoutError,
	isForbiddenError,
	isBadRequestError,
	isNotFoundError,
	isLoadingChunkError,
	isTwilioMediaError,

	/**
	 * This note is made on 13 December 2022.
	 * Wasn't sure why we previously prevent sending Twilio Signalling Error to Sentry.
	 * Thus, for now, we commented out the next line and see where it leads us.
	 * Hoping the error will help us figure out the video call issue that happens lately.
	 * */
	// isTwilioSignallingError,
];

/**
 * Returns true if one of the errorFilterFns is true.
 * This function is used to surpress sentry messages by filtering
 * errors that don't give value for sentry reports.
 *
 * @param err
 */
export function shouldFilterError(err: Error) {
	return errorFilterFns.some((fn) => fn(err));
}
