/**
 * Author: Umar Date: 07/02/2019
 */

export enum AnalyticsInternalEvent {
	// App Events
	APP_INITIALIZED = "APP_INITIALIZED",
	APP_CALL_WINDOW_INITIALIZED = "APP_CALL_WINDOW_INITIALIZED",

	// Browser Events
	BROWSER_BLUR = "BROWSER_BLUR",
	BROWSER_FOCUS = "BROWSER_FOCUS",
	BROWSER_OFFLINE = "BROWSER_OFFLINE",
	BROWSER_ONLINE = "BROWSER_ONLINE",
	BROWSER_BEFORE_UNLOAD = "BROWSER_BEFORE_UNLOAD",

	// User Events
	USER_TRIGGER = "USER_TRIGGER",
	USER_LOGOUT = "USER_LOGOUT",
}

// Nicer screen names to be used by analytics
export enum AnalyticsScreenNames {
	UNKNOWN = "Unknown",
	NOT_LOGGED_IN = "NotLoggedIn",

	APP = "Dashboard",
	APP_THERAPIST_INFO = "MyProfile",
	APP_THERAPIST_EDIT_BIO = "MyProfile_EditBio",
	APP_THERAPIST_EDIT_PERSONAL = "MyProfile_EditDetails",
	APP_THERAPIST_EDIT_AVATAR = "MyProfile_EditAvatar",
	APP_CLIENT = "ClientPage",
	APP_THERAPIST = "TherapistPage",
	APP_CONNECTION_REQUEST = "ConnectionRequests",
	APP_LIBRARY = "Library",
	APP_HELPDESK = "Helpcenter",

	APP_VIDEO_CALL = "CallWindow",
}

export enum AnalyticsEvent {
	// Callback Events
	TWILIO_NETWORK_QUALITY = "TWILIO_NETWORK_QUALITY",
	TWILIO_RECONNECTING = "TWILIO_RECONNECTING",
	TWILIO_RECONNECTED = "TWILIO_RECONNECTED",
	TWILIO_DISCONNECTED = "TWILIO_DISCONNECTED",
	TWILIO_PARTICIPANT_CONNECTED = "TWILIO_PARTICIPANT_CONNECTED",
	TWILIO_PARTICIPANT_DISCONNECTED = "TWILIO_PARTICIPANT_DISCONNECTED",
	CALL_WINDOW_CRASH = "CALL_WINDOW_CRASHED",

	// Rate Call Quality Events
	RATE_CALL_QUALITY = "RATE_CALL_QUALITY",

	// EMDR Events
	OPEN_EMDR_PANEL = "EMDR/OPEN_EMDR_PANEL",
	STARTING_EMDR = "EMDR/STARTING_EMDR",
	EMDR_IS_SUCCESSFULLY_STARTED = "EMDR/EMDR_IS_SUCCESSFULLY_STARTED",
	EMDR_PAYLOAD_IS_CHANGED = "EMDR/EMDR_PAYLOAD_IS_CHANGED",

	// Organization Events
	SHOW_ORG_SELECTION_DIALOG = "ORGANIZATION/SHOW_ORGGANIZATION_SELECTION_DIALOG",
	// TODO: Remove this after the migration is done
	SHOW_NEW_ORG_SELECTION_DIALOG = "ORGANIZATION/SHOW_NEW_ORGANIZATION_SELECTION_DIALOG",

	// Niceday blog search Events
	OPEN_SEARCH_DIALOG = "NDBLOG_SEARCH/OPEN_SEARCH_DIALOG",
	VISIT_SEARCH_RESULT_LINK = "NDBLOG_SEARCH/VISIT_SEARCH_RESULT_LINK",

	// File sharing events
	CLICK_ATTACHMENT_BUTTON = "FILE_SHARING/CLICK_ATTACHMENT_BUTTON",
	DROP_FILE = "FILE_SHARING/DROP_FILE",
	DOWNLOAD_PDF = "FILE_SHARING/DOWNLOAD_PDF",
	DOWNLOAD_IMAGE = "FILE_SHARING/DOWNLOAD_IMAGE",
	OPEN_PDF_IN_NEW_TAB = "FILE_SHARING/OPEN_PDF_IN_NEW_TAB",
	OPEN_IMAGE_IN_NEW_TAB = "FILE_SHARING/OPEN_IMAGE_IN_NEW_TAB",
	OPEN_FILES_TAB = "FILE_SHARING/OPEN_FILES_TAB",

	OPEN_ROM_QUICK_GUIDE_DIALOG = "ROM/OPEN_ROM_QUICK_GUIDE_DIALOG",
	OPEN_ROM_INSTRUCTION_DIALOG = "ROM/OPEN_ROM_INSTRUCTION_DIALOG",

	// toolbar events
	ACCESS_FROM_TOOLBAR = "ACCESS_FROM_TOOLBAR",

	// behavior experiment events
	DELETED_BEHAVIOR_EXPERIMENT = "BEHAVIOR_EXPERIMENT/DELETED_BEHAVIOR_EXPERIMENT",

	// invite clients
	INVITE_CLIENT_BUTTON = "INVITE_CLIENTS/INVITE_CLIENT_BUTTON",
	SEND_INVITE_CLIENT = "INVITE_CLIENTS/SEND_INVITE_CLIENT",
	RESEND_INVITE_CLIENT = "INVITE_CLIENTS/RESEND_INVITE_CLIENT",

	// preset
	ACTIVATE_PRESET = "PRESETS/ACTIVATE_PRESET",

	// start call screen
	OUTGOING_CALL_PLATFORM = "START_CALL_SCREEN/OUTGOING_CALL_PLATFORM",
}
