import {TIME} from "constants/time";
import Storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";

/**
 * Returns token from localStorage
 */
export function getSessionId(): string {
	return Storage.read(StorageKeys.SESSION_ID) as string;
}

export function getSessionExpiryDate(): Date | null {
	const tokenExpiryDateString = Storage.read(StorageKeys.TOKEN_EXPIRY) as string;
	if (!tokenExpiryDateString) {
		return null;
	}
	return new Date(tokenExpiryDateString);
}

/**
 * Returns auth userId from localStorage
 */
export function getAuthUserId(): number | null {
	const userId = Storage.read(StorageKeys.USER_ID);
	return userId ? Number(userId) : null;
}

/**
 * Returns auth userId from localStorage
 */
export function getAuthUserExternalId(): string {
	return Storage.read(StorageKeys.EXTERNAL_ID) as string;
}

/**
 * Returns auth email from localStorage
 */
export function getAuthUserEmail(): string | null {
	const email = Storage.read(StorageKeys.EMAIL) as string;
	return email || null;
}

/** Block login duration */
const BLOCK_DURATION_MS = 5 * TIME.MILLISECONDS_IN_MINUTE;

type BlockLoginType = "2FA_login" | "2FA_passwordReset";
type BlockLoginData = {
	/** Blocked timestamp */
	timestamp: number;
	/** Type of blocking (Caused by what? Login or Password reset or other cause?) */
	type: BlockLoginType;
};

export function getBlockLoginData(): BlockLoginData | null {
	return Storage.readFromLocalStorage<BlockLoginData>(StorageKeys.BLOCK_LOGIN);
}

/**
 * Returns true if user is blocked from logging in
 */
export function isLoginBlocked(): boolean {
	const data = getBlockLoginData();
	if (!data || !data.type || !data.timestamp) {
		return false;
	}

	if (isNaN(data.timestamp)) {
		return false;
	}

	const blockedUntilMS = data.timestamp + BLOCK_DURATION_MS;
	return blockedUntilMS >= Date.now();
}

/**
 * Block login by setting a timestamp
 */
export function blockLogin(type: BlockLoginType): void {
	const data: BlockLoginData = {
		type,
		timestamp: Date.now(),
	};
	Storage.write(StorageKeys.BLOCK_LOGIN, JSON.stringify(data));
}

/**
 * Unblock login by removing the block timestamp
 */
export function unblockLogin(): void {
	Storage.remove(StorageKeys.BLOCK_LOGIN);
}

/**
 * Returns auth user last login date and time
 */
export function getUserLastLogin(): string | null {
	const lastLoginTime = Storage.read(StorageKeys.LAST_LOGIN) as string;
	return lastLoginTime || null;
}
