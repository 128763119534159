/**
 * Created by leo on 04/07/2017.
 * List of possible logging levels
 */

export enum LogLevels {
	DEBUG = 1,
	INFO = 2,
	WARN = 3,
	ERROR = 4,
	OFF = 6,
}
