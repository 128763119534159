////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// lib imports
import autobind from "autobind-decorator";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// app imports
import {AppConfig} from "../ts/app/AppConfig";
import {BrowserEvents} from "constants/BrowserEvents";
import {AnalyticsInternalEvent} from "./AnalyticsEventTypes";
import {IAnalyticsPageProperties, IAnalyticsProperties} from "./IAnalyticsProperties";
import {Utils} from "utils/Utils";
import createLogger from "../logger/createLogger";
import {ActionType} from "typesafe-actions";
import {
	trackReduxActions,
	getContactPropertiesFromRelativePath,
	getCategoryNameFromRelativePath,
	getScreenSizeInfo,
} from "./analyticsHelpers";

/**
 *  Service to track user and browser events for analytics
 */
@autobind
class AnalyticsService {
	private log = createLogger("AnalyticsService");

	// Properties of the current portal instance
	private sessionProperties: IAnalyticsProperties;

	public static INSTANCE_ID: string = Utils.guid();

	/**
	 * Tracks the current page based on window.location.pathname
	 */
	public trackCurrentPage(pageProperties?: IAnalyticsPageProperties): void {
		// Get the current path from the window location
		const relativePath = window.location.pathname;

		// Get a category name for the current path
		let categoryName = getCategoryNameFromRelativePath(relativePath);

		// Get client properties from the current path
		const clientProperties = getContactPropertiesFromRelativePath(relativePath);

		// If there is no trigger name set, then set the default which is user triggered
		if (!pageProperties) {
			pageProperties = {triggerName: AnalyticsInternalEvent.USER_TRIGGER};
		}

		let pageName = categoryName;

		// Currently we only have sub navigation in the client page, we can move this logic to a different function when we add more pages with sub page navigation
		if (clientProperties && clientProperties.detailPageName) {
			pageName = clientProperties.detailPageName;
			clientProperties.detailPageName = undefined;
		}

		analytics.page(categoryName, pageName, {
			...this.getEventProperties(),
			...clientProperties,
			...pageProperties,
		});
	}

	/**
	 * Sends an event to segment analytics
	 */
	public trackEvent(eventName: string, data?: object): void {
		analytics.track(eventName, {...this.getEventProperties(), ...data});
	}

	/**
	 * Helper to send dispatched redux actions to segment analytics.
	 * Also provides additional properties to the event
	 */
	public trackReduxActions<ActionTypes = string, Action = ActionType<any>>(
		actionTypes: ActionTypes[],
		propertiesFn: (action: Action) => any,
	) {
		return trackReduxActions(actionTypes, (...args) => {
			return {...propertiesFn(...args), ...this.getEventProperties()};
		});
	}

	/**
	 * Initialize segment for main window
	/**
	 * @inheritDoc
	 */
	public init(): void {
		this.initialize();

		// Track the app initialized event
		this.trackInternalEvent(AnalyticsInternalEvent.APP_INITIALIZED);
	}

	/**
	 * Initialize segment for video call window
	 */
	public initCallWindow(): void {
		this.initialize();

		// Track the call window initialized event
		this.trackInternalEvent(AnalyticsInternalEvent.APP_CALL_WINDOW_INITIALIZED);
	}

	/**
	 * Set `parentInstanceID`.
	 * This is used in call window to know the parent window instance id
	 */
	public setParentInstanceID(parentID: string) {
		if (this.sessionProperties) {
			this.sessionProperties.parentInstanceID = parentID;
		} else {
			this.log.warn("Calling setParentInstanceID before initializing analytics service");
		}
	}

	/**
	 * Initialize the analytics service
	 */
	private initialize(): void {
		// Create a GUID that we attach to each track call in this browser instance
		this.sessionProperties = {portalInstanceID: AnalyticsService.INSTANCE_ID, portalVersion: AppConfig.version};

		// Initialize Segment Analytics
		// Set `Secure` cookie flag to true due security issue
		// See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/persistence/#cookie-settings
		// @ts-ignore
		analytics.load(AppConfig.SEGMENT_ANALYTICS_KEY, {cookie: {secure: true}});

		// Clear existing listeners if any
		this.removeListeners();

		// Setup listeners for browser events
		window.addEventListener(BrowserEvents.BLUR, this.onWindowInBackground);
		window.addEventListener(BrowserEvents.FOCUS, this.onWindowInForeground);
		window.addEventListener(BrowserEvents.OFFLINE, this.onOffline);
		window.addEventListener(BrowserEvents.ONLINE, this.onOnline);
		window.addEventListener(BrowserEvents.BEFORE_UNLOAD, this.onBeforeUnload);
	}

	/**
	 * Removes the browser event listeners
	 */
	private removeListeners(): void {
		window.removeEventListener(BrowserEvents.BLUR, this.onWindowInBackground);
		window.removeEventListener(BrowserEvents.FOCUS, this.onWindowInForeground);
		window.removeEventListener(BrowserEvents.OFFLINE, this.onOffline);
		window.removeEventListener(BrowserEvents.ONLINE, this.onOnline);
		window.removeEventListener(BrowserEvents.BEFORE_UNLOAD, this.onBeforeUnload);
	}

	/**
	 * @inheritdoc
	 */
	public getInstanceID(): string {
		return AnalyticsService.INSTANCE_ID;
	}

	/**
	 * @inheritDoc
	 */
	public reset(): void {
		analytics.reset();

		this.removeListeners();
	}

	/**
	 * Tracks internal AnalyticsInternalEvents to Segment
	 *
	 * @param event The AnalyticsInternalEvents that needs to be tracked
	 */
	private trackInternalEvent(event: AnalyticsInternalEvent): void {
		// Generate a eventID has that is used to link the track and page calls in analytics
		const eventID = Utils.guid();

		// We track the current page and the event both track is called and link them with the eventID
		this.trackCurrentPage({triggerName: event, eventID: eventID});
		analytics.track(event, {...this.getEventProperties(), eventID: eventID});
	}

	/**
	 * Returns additional event properties
	 */
	private getEventProperties() {
		const defaultProperties = {
			sizes: getScreenSizeInfo(),
		};
		return {
			...defaultProperties,
			...this.sessionProperties,
		};
	}

	/** Interesting browser events to track  */
	private onWindowInBackground(): void {
		this.trackInternalEvent(AnalyticsInternalEvent.BROWSER_BLUR);
	}

	private onWindowInForeground(): void {
		this.trackInternalEvent(AnalyticsInternalEvent.BROWSER_FOCUS);
	}

	private onOffline(): void {
		this.trackInternalEvent(AnalyticsInternalEvent.BROWSER_OFFLINE);
	}

	private onOnline(): void {
		this.trackInternalEvent(AnalyticsInternalEvent.BROWSER_ONLINE);
	}

	private onBeforeUnload(): void {
		this.trackInternalEvent(AnalyticsInternalEvent.BROWSER_BEFORE_UNLOAD);
	}
}

const analyticsService = new AnalyticsService();
export default analyticsService;
