/**
 * Created by leo on 04/07/2017.
 */

export class TypeOf {
	public static readonly STRING = "string";
	public static readonly NUMBER = "number";
	public static readonly BOOLEAN = "boolean";
	public static readonly NULL = "null";
	public static readonly UNDEFINED = "undefined";

	public static readonly OBJECT = "object";
	public static readonly FUNCTION = "function";
}
