/**
 * Author: leo Date: 27/03/2018
 */

export enum BrowserEvents {
	AFTER_PRINT = "afterprint",
	BEFORE_PRINT = "beforeprint",
	BEFORE_UNLOAD = "beforeunload",
	BLUR = "blur",
	ERROR = "error",
	FOCUS = "focus",
	HASHCHANGE = "hashchange",
	LOAD = "load",
	MESSAGE = "message",
	OFFLINE = "offline",
	ONLINE = "online",
	ORIENTATIONCHANGE = "orientationchange",
	PAGEHIDE = "pagehide",
	PAGESHOW = "pageshow",
	POPSTATE = "popstate",
	RESIZE = "resize",
	SCROLL = "scroll",
	STORAGE = "storage",
	UNLOAD = "unload",
	DOM_CONTENT_LOADED = "DOMContentLoaded",
	WHEEL = "wheel",
}
