/**
 * Author: leo Date: 21/11/2017
 */

import moment from "moment";

export enum TIME {
	TODAY = "TODAY",
	THIS_WEEK = "THIS_WEEK",
	LAST_WEEK = "LAST_WEEK",
	EARLIER = "EARLIER",

	MILLISECONDS_IN_24HOURS = 864e5, // = 86400000,
	MILLISECONDS_IN_HOUR = 3600_000,
	MILLISECONDS_IN_HALF_MINUTE = 30_000,
	MILLISECONDS_IN_MINUTE = 60_000,
	MILLISECONDS_IN_2_MINUTES = 120_000,
	MILLISECONDS_IN_SECOND = 1000,

	TWO_MINUTES_MS = 120_000,

	FIVE_MINUTES_SEC = 300,
	TEN_MINUTES_SEC = 600,
	TWENTY_MINUTES_SEC = 1_200,

	DAYS_IN_WEEK = 7,

	HOURS_IN_DAY = 24,

	DAYS_IN_MONTH_30 = 30,

	MINUTES_IN_DAY = 1440,
	MINUTES_IN_HOUR = 60,
	SECONDS_IN_DAY = 86400,
	SECONDS_IN_HOUR = 3600,
	SECONDS_IN_MINUTE = 60,

	//todo: check if the values below are needed in the new Goalie too.
	// DAY_SUNDAY = 0,
	// DAY_MONDAY = 1,
	// DAY_TUESDAY = 2,
	// DAY_WEDNESDAY = 3,
	// DAY_THURSDAY = 4,
	// DAY_FRIDAY = 5,
	// DAY_SATURDAY = 6
}

export enum TIME_UNITS {
	YEARS = "years",
	MONTHS = "months",
	WEEKS = "weeks",
	DAYS = "days",
	HOURS = "hours",
	MINUTES = "minutes",
	SECONDS = "seconds",
	MILLISECONDS = "milliseconds",
	DAY = "day",
}

export namespace TimeUtils {
	/**
	 * Returns difference in days between two moment objects.
	 * @param {Moment} d1
	 * @param {Moment} d2
	 */
	export function getDayDiff(d1: moment.Moment, d2: moment.Moment): number {
		return Math.abs(d1.startOf(TIME_UNITS.DAY).diff(d2.startOf(TIME_UNITS.DAY), TIME_UNITS.DAYS));
	}
}
