import {AppConfig} from "../ts/app/AppConfig";
import createSentryReport, {SentryTags} from "../errorHandler/createSentryReport";
import {LogLevels} from "./LogLevels";
import {TypeOf} from "../ts/services/utils/constants/TypeOf";
import moment from "moment";
import {Breadcrumb} from "@sentry/react";

/**
 * Creates helpers for logging and sentry reporting
 *
 * @param {string} logPrefix will be printed as a prefix to the console. e.g: [prefix] .....
 * @param {string} sentryTag string to be indexed in sentry so that we can easily search the logs. IF tag is not provided, errors won't be indexed
 */
function createLogger(logPrefix?: string, sentryTag?: SentryTags) {
	const sentryReport = createSentryReport(sentryTag);
	const makePrefix = () => (logPrefix ? [makeTimeString(), `[${logPrefix}]`] : []);
	return {
		debug: (...args: any[]) => {
			if (LogLevels.DEBUG >= AppConfig.logLevel) {
				console.debug(...makePrefix(), ...args);
			}
		},
		info: (...args: any[]) => {
			if (LogLevels.INFO >= AppConfig.logLevel) {
				console.info(...makePrefix(), ...args);
			}
		},
		/**
		 * Logs warning message to console AND sentry as message
		 */
		warn: (...args: any[]) => {
			if (LogLevels.WARN >= AppConfig.logLevel) {
				console.warn(...makePrefix(), ...args);
			}
			sentryReport.captureWarningMessage(convertLogsIntoString(args));
		},
		/**
		 * Logs error message to console AND sentry as message
		 */
		error: (...args: any[]) => {
			if (LogLevels.ERROR >= AppConfig.logLevel) {
				console.error(...makePrefix(), ...args);
			}
			sentryReport.captureErrorMessage(convertLogsIntoString(args));
		},
		/**
		 * Report error to sentry as object. You can add additional message as extra information for sentry.
		 * Also logs error object to console.
		 */
		captureException: (errObj: any, extras?: {message?: string; [key: string]: any}) => {
			if (LogLevels.ERROR >= AppConfig.logLevel) {
				console.error(...makePrefix(), errObj, extras);
			}
			sentryReport.captureException(errObj, extras);
		},
		/**
		 * Capture error message. You can add additional message as extra information for sentry.
		 * Also logs error object to console.
		 */
		captureMessage: (errorMsg: string, extras?: {[key: string]: any}) => {
			if (LogLevels.ERROR >= AppConfig.logLevel) {
				console.error(...makePrefix(), errorMsg, extras);
			}
			sentryReport.captureErrorMessage(errorMsg, extras);
		},
		/**
		 * Add more into sentry breadcrumbs
		 * More breadcrumbs means more data to debug
		 */
		addBreadcrumb: (breadcrumb: Breadcrumb) => {
			sentryReport.addBreadcrumb(breadcrumb);
			if (LogLevels.DEBUG >= AppConfig.logLevel) {
				console.debug(...makePrefix(), "Breadcrumb", breadcrumb);
			}
		},
	};
}

function makeTimeString(): string {
	return moment().format("HH:mm:ss");
}

function convertLogsIntoString(items: any[]): string {
	let i,
		item,
		type,
		result: string = "",
		isErrorObj: boolean = false;

	for (i = 0; i < items.length; i++) {
		item = items[i];

		if (!item) {
			continue;
		}

		type = typeof item;

		// the object is an error object if the conditions (1) and (2) below hold true.
		isErrorObj =
			type === TypeOf.OBJECT &&
			item &&
			item.name &&
			item.name.toString().toLowerCase().indexOf("error") > -1 && // (1) Check if the object has property `.name` which contains the word `error`
			item.stack; // (2) check if the object has property `.stack`.

		if (isErrorObj) {
			// an error object needs to be treated in a special way: `.stack` needs to be read.
			result += item.stack + " "; // result += item.toString() + " | " + item.stack;
		} else if (type === TypeOf.OBJECT || type === TypeOf.FUNCTION) {
			result += JSON.stringify(item) + " ";
		} else {
			result += item.toString() + " ";
		}
	}
	return result;
}

export default createLogger;
