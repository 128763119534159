import createMuiTheme, {Theme, ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {isTablet} from "react-device-detect";

const nicedayBoxShadow: string = "0 5px 10px 0 rgba(0, 0, 0, 0.05)";

export default function createNicedayTheme(options: ThemeOptions): Theme {
	return createMuiTheme({
		palette: {
			primary: {
				main: "#7B61FF",
				contrastText: "#fff",
			},
			secondary: {
				main: "#df665a",
				contrastText: "#fff",
			},
			text: {
				primary: "#404040",
			},
			success: {
				main: "#39ca74",
				contrastText: "#fff",
			},
			warning: {
				main: "#ff9800",
				contrastText: "#fff",
			},
			error: {
				main: "#df665a",
				contrastText: "#fff",
			},
		},
		typography: {
			allVariants: {
				color: "#404040",
			},
			button: {
				textTransform: "none",
			},
		},
		overrides: {
			MuiButton: {
				contained: {
					color: "#404040",
					backgroundColor: "#efeff4",
				},
			},
			MuiTabs: {
				indicator: {
					backgroundColor: "#fff",
				},
			},
			MuiPaper: {
				elevation1: {
					boxShadow: nicedayBoxShadow,
				},
				elevation2: {
					boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
				},
			},
			MuiAppBar: {
				root: {
					minHeight: 70,
				},
			},
			MuiToolbar: {
				root: {
					minHeight: 70,
				},
			},
			MuiDialog: {
				paperWidthSm: {
					maxWidth: "560px",
				},
			},
			MuiTooltip: {
				tooltip: {
					fontSize: 14,
				},
			},
		},
		emdrPanel: {width: isTablet ? "350px" : "480px"},
		networkQuality: {levelColors: ["#adb5bd", "#e23932", "#e23932", "#f39103", "#60db52", "#60db52"]},
		...options,
	});
}
